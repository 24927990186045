.dm-edit-pixabay__popper {
	z-index: 10000;
	box-sizing: border-box;
	padding: 0 10px;
	background-color: #F5F7F7;
	margin-top: -3px !important;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
