.dm-edit-landing {
	width: 1300px;
	min-height: 600px;
	margin-bottom: 50px;
	position: relative;

	&:not(.dm-edit-landing--live):before {
		box-sizing: border-box;
		content: '';
		position: absolute;
		height: 100%;
		width: 900px;
		border: 1px dashed lightgrey;
		left: 200px;
		border-top: none;
		border-bottom: none;
	}
}

.dm-edit-landing--mobile {
	width: 396px;

	&:before {
		display: none;
	}

	.dm-edit-section {
		padding: 0;
	}

	.dm-edit-section--grid {
		grid-auto-rows: 8px;
	}

	.dm-edit-section--columns {
		flex-direction: column;
	}

	.dm-edit-section__grid {
		width: 100%;
		left: 0;
		background-size: 11px 8px;
	}

	.dm-edit-section-column {
		grid-auto-rows: 8px;

		&:before {
			display: none;
		}
	}

	.dm-edit-section-column__grid {
		background-size: 11px 8px;
	}
}

.dm-edit-landing--live {
	.dm-edit-section-column:before,
 	.dm-edit-section-column__grid,
	.dm-edit-section__grid {
		display: none;
	}
}
