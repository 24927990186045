.dm-edit-video {
	display: flex;
	align-items: center;
	justify-content: center;
	
	video {
		max-width: 100%;
		max-height: 100%;
	}
}

.dm-edit-video--empty {
	border: 1px solid darkgrey;
}
