.dm-edit-images {
	display: flex;
	flex-wrap: wrap;
}

.dm-edit-images--list {
	flex-direction: column;
}

.dm-edit-images--tiles {
	margin: 0 -4px;
}

.dm-edit-imgs-layout.MuiIconButton-root {
	width: 32px;
	height: 32px;
	background-color: #F5F7F7;
	border-radius: 4px;
	color: #7E8996;
	margin-left: 4px;

	svg {
		width: 20px;
		height: 20px;
	}
}
