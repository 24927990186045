.dm-edit-workspace {
	height: calc(100vh - 56px);
	box-sizing: border-box;
	overflow: scroll;
  background: #E6ECF0;
	flex: 1;
	position: relative;
}

.dm-edit-workspace__bar--top {
	top: 0;
}

.dm-edit-workspace__bar--bottom {

}

.dm-edit-workspace__zoom {
	width: 50px;
	margin-left: 10px;
	font-size: 16px;
	text-align: right;
}

.dm-edit-card-wrapper {
	margin: 0 auto;
	transform-origin: 0% 0% 0px;
	// padding: 0 50px 50px 50px;
	width: fit-content;
	position: absolute;
	left: 50%;
}

.dm-edit-cardscale {
	margin: 0 auto;
	position: relative;
	padding: 35px 25px 0;
	min-height: calc(100vh - 181px);
}
