.dm-edit-properties {
	padding-bottom: 16px;
	overflow-x: hidden;
	height: 100%;
	box-sizing: border-box;
}

.dm-edit-properties__empty {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	line-height: 16px;

	div:last-of-type {
		color: #7E8996;
		margin-top: 4px;
	}

	svg {
		height: 36px; 
		width: 'auto';
		margin-bottom: 20px;
	}
}

.dm-edit-item-cntrl {
	width: 100%;
	background-color: #1868FD;
	color: #FFF;
	border-radius: 4px;
	margin: 8px 0;
	line-height: 18px;
	padding: 4px 12px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;

	> span {
		display: flex;
		align-items: center;

		svg {
			color: #FFF;
			width: 18px;
			height: 18px;
			margin-right: 6px;
		}
	}

	.MuiIconButton-root svg {
		width: 16px;
		height: 16px; 
		color: #FFF;
	}
}

.dm-edit-layout__box {
	border: 1px dashed #B7BFC9;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 32px 44px;
}

.dm-edit-layout__box--margin {
	height: 184px;
	margin-bottom: 12px;

	&::before {
		content: 'Margin';
	}
}

.dm-edit-layout__box--border {
	border-style: solid;
	pointer-events: none;

	&::before {
		content: 'Padding';
	}
}

.dm-edit-layout__box--margin,
.dm-edit-layout__box--border {
	position: relative;

	&::before {
		font-size: 12px;
		opacity: 0.5;
		display: block;
		position: absolute;
		left: 10px;
		top: 8px;
	}
}

.dm-edit-layout__box--padding {
	padding: 16px 44px;
}

.dm-edit-layout__box-btn.MuiButton-root {
	padding: 0;
	min-width: 38px;
	height: 26px;
	position: absolute;
	opacity: 0.5;
	color: #202B38;

	&:hover {
		opacity: 1;
		color: #1868FD;
	}

	&.dm-edit-layout__box-btn--marginTop {
		top: 3px;
		left: 50%;
		transform: translateX(-50%);
	}

	&.dm-edit-layout__box-btn--marginBottom {
		bottom: 3px;
		left: 50%;
		transform: translateX(-50%);
	}

	&.dm-edit-layout__box-btn--marginLeft {
		top: 50%;
		left: 3px;
		transform: translateY(-50%);
	}

	&.dm-edit-layout__box-btn--marginRight {
		top: 50%;
		right: 3px;
		transform: translateY(-50%);
	}

	&.dm-edit-layout__box-btn--paddingTop {
		top: 36px;
		left: 50%;
		transform: translateX(-50%);
	}

	&.dm-edit-layout__box-btn--paddingBottom {
		bottom: 36px;
		left: 50%;
		transform: translateX(-50%);
	}

	&.dm-edit-layout__box-btn--paddingLeft {
		top: 50%;
		left: 48px;
		transform: translateY(-50%);
	}

	&.dm-edit-layout__box-btn--paddingRight {
		top: 50%;
		right: 48px;
		transform: translateY(-50%);
	}
}


.dm-edit-layout__popover-btns {
	margin: -2px;
}

.dm-edit-layout__popover-btn.MuiButton-root {
	min-width: 40px;
	width: 40px;
	margin: 2px;
	height: 32px;
	font-weight: 400;
	font-size: 12px;

	.dm-edit-layout__popover-top & {
		margin: 0;
	}
} 


.dm-edit-layout__popover {
	padding: 16px;
	width: 260px;
}

.dm-edit-layout__popover-top {
	display: flex;
	margin: 16px 0;
}

.dm-edit-property-fields {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 8px;
	margin: 8px 0;

	.dm-edit-btn.MuiButton-root {
		justify-content: flex-start;
	}

	.MuiFormControl-root {
		height: 32px;
		font-weight: 400;
		color: #202B38;
		font-size: 12px;
	}
	
	.dm-edit-btn.MuiButton-root {
		width: 82px;
		height: 32px;
		font-weight: 400;
		color: #202B38;
		font-size: 12px;
	}

	.MuiInputBase-root {
		width: 100%;
	}
}

.dm-edit-property__btngroup {
	height: 32px;
	border-radius: 4px;
	background-color: #F5F7F7;
	margin: 4px;
	display: flex;
	padding: 1px;
	box-sizing: border-box;
}

.dm-edit-property__btngroup--auto {
	width: auto;
}

.dm-edit-property__border {
	width: 14px;
	height: 14px;
	box-sizing: border-box;
	border-radius: 3px;
	border: 2px solid #B5BFCA;
	background-color: #FFFFFF;
	position: relative;
}

.dm-edit-property__border--full {
	border-color: #1868FD;
}

.dm-edit-property__border--borderLeft {
	border-left-color: #1868FD;
}

.dm-edit-property__border--borderRight {
	border-right-color: #1868FD;
}

.dm-edit-property__border--borderTop {
	border-top-color: #1868FD;
}

.dm-edit-property__border--borderBottom {
	border-bottom-color: #1868FD;
}

.dm-edit-property__border--separateCorners {
	border: none;
	background: 0;

	svg {
		position: absolute;
		width: 14px;
		height: 14px;
		top: 0;
		left: 0;
	}
}

.dm-edit-property__corners {
	width: 15px;
	height: 15px;
	position: relative;
	margin-left: auto;
}

.dm-edit-property__corner {
	width: 5px;
	height: 5px;
	position: absolute;
}

.dm-edit-property__corner--active {
	color: #1868FD;
}

.dm-edit-property__corner--borderTopLeftRadius {
	top: 0;
	left: 0;
}

.dm-edit-property__corner--borderTopRightRadius {
	right: 0;
	top: 0;
	transform: rotate(90deg);
}

.dm-edit-property__corner--borderBottomRightRadius {
	right: 0;
	bottom: 0;
	transform: rotate(180deg);
}

.dm-edit-property__corner--borderBottomLeftRadius {
	left: 0;
	bottom: 0;
	transform: rotate(-90deg);
}

.dm-edit-property__mixed {
	width: 14px;
	height: 14px;
}

.MuiModal-root .MuiList-root .MuiMenuItem-root {
	font-size: 12px;
	line-height: 18px;
	font-family: 'Inter', sans-serif;
}

.dm-edit-property__step.MuiButton-root {
	border: 1px solid #EAECEE;
	width: 32px;
	height: 32px;
	min-width: 32px;
	font-size: 12px;
	font-weight: 700;
	color: rgb(32, 43, 56);

	&.dm-edit-property__step--inactive {
		background-color: #F5F7F7;
		color: rgba(32, 43, 56, 0.5);
	}

	&.dm-edit-property__step--add {
		color: #1868FD;
	}
}
