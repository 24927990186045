.dm-edit-web-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 50%;
}

.dm-edit-web-image--contain {
	object-fit: contain;
}
