.dm-edit-rightbar {
	max-height: 100vh;
	height: 100vh;
	box-sizing: border-box;
	padding: 66px 16px 0px 16px;
	width: 321px;
	z-index: 1200;
	display: flex;
	flex-direction: column;
	overflow: scroll;
	border-left: 1px solid #E7E9EB;
	overflow: hidden;
	background-color: #FFF;
}

.dm-edit-rightbar__selection {
	width: 100%;
	background-color: #E7E9EB;
	border-radius: 4px;
	height: 32px;
	display: flex;

	.MuiButton-root {
		flex: 1;
		margin: 2px;
		text-transform: none;
		border-radius: 4px;
		opacity: 0.5;
		color: #2A394A;

		&.dm-edit-rightbar__active {
			background-color: #fff;
			box-shadow: 0 2px 8px 0 rgba(37,50,65, 0.16);
			opacity: 1;
		}
	}
}

.dm-edit-rightbar__section {
	flex: 1;
	overflow: scroll;
}
