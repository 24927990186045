
.dm-edit-toptext__select.MuiSelect-select {
  padding: 3px 32px 3px 14px;
}

.dm-edit-text {
  height: auto;
  position: relative;
  word-break: normal;
  z-index: 0;
  overflow: hidden;

  p {
    margin: 0;
  }

  &.component-selected {
    z-index: 2;
  }
}

.dm-edit-text--editing {
  overflow: visible;
}

.dm-edit-text__cntrls {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  width: 100%;
  min-width: 204px;
  display: flex;
  background-color: #F5F7F7;

  > div {
    height: 33px;
    max-height: 33px;
    margin: 0;
    width: auto;

    .MuiButton-root {
      max-width: 33px;
      height: 29px;
    }

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.dm-edit-text {
  .DraftEditor-editorContainer, 
  .DraftEditor-root, 
  .public-DraftEditor-content,
  .public-DraftStyleDefault-ltr {
    text-align: inherit;
  }

  .DraftEditor-editorContainer {
    border-left: none;
  }
}
