.dm-edit-dropzone {
	flex: 1;
	background-color: #FAFBFB;
	border-radius: 8px;
	padding: 30px;
	border: 1px dashed #979CA4;
	color: #202B38;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 14px;
	font-weight: bold;

	&>div {
		font-size: 14px;
		line-height: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;

		span,
		button, 
		svg {
			display: block;
		}

		span {
			width: 160px;
		}

		svg {
			margin-bottom: 16px;
			width: 28px;
			height: 28px;
		}
	}
}

.dm-edit-dropzone--active {
	cursor: copy;
	border: 2px solid #1868FD;

	&>div {
		opacity: 0.4;
	}
}

.dm-edit-dropzone__input {
	display: none;
}

.dm-edit-dropzone__note {
	margin-top: 24px;
	font-size: 12px;
	line-height: 16px;
	font-weight: 400;
	color: #7E8996;
}
