.dm-edit-header {
	position: fixed;
	top: 0;
	left: 0;
	height: 56px;
	width: 100%;
	z-index: 1201;
	background-color: #fff;
	box-shadow: 8px 0 20px 0 rgba(171,171,171, 0.2);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 16px 0 0;
	box-sizing: border-box;

	.Mui-disabled {
		opacity: 0.6;
	}
	svg {
		color: #7E8996;
	}

	section {
		display: flex;
		align-items: center;
	}
}

.dm-edit-header__campaign {
	font-size: 16px;
	line-height: 22px;
	font-weight: bold;
}

.dm-edit-header__client {
	font-size: 12px;
	line-height: 16px;
	letter-spacing: -0.17px;
}

.dm-edit-header__divider {
	margin: 0 4px;
	background-color: #DADFE3;
	height: 32px;
	width: 1px;
}

.dm-edit-header__icon.MuiIconButton-root {
	width: 40px;
	height: 40px;
	margin: 0 2px;

	svg {
		color: #2A394A;
		width: 18px;
		height: 18px;
	}

	&.dm-edit-header__icon--inactive svg {
		color: rgba(0, 0, 0, 0.26);
	}
}

.dm-edit-header__btn.MuiButton-root {
	min-width: 32px;
	margin: 0 2px;
	background: #F5F7F7;
	color: #2A394A;
	line-height: 20px;
	

	&.dm-edit-header__btn--lft {
		margin-left: 8px;
		font-size: 20px;
	}

	&.dm-edit-header__btn--cntr {
		font-weight: 400;
		font-size: 12px;
		letter-spacing: -0.17;
		min-width: 52px;
	}

	&.dm-edit-header__btn--rght {
		margin-right: 8px;
		font-size: 20px;
	}
}
