.dm-edit-section-column {
	flex: 1;
	display: grid;
  grid-auto-rows: 15px;
	position: relative;

	&:not(:first-of-type) {
		&:before {
			box-sizing: border-box;
			content: '';
			position: absolute;
			height: 100%;
			border: 1px dashed lightgrey;
			left: 0;
			border-top: none;
			border-bottom: none;
		}
	}
}

.dm-edit-section-column__grid {
  position: absolute;
	background-image: url('./../grid.svg');
	background-size: 25px 15px;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 0;
	pointer-events: none;
}
