.dm-edit-toolbar {
	background-color: #FFF;
	height: 100vh;
	box-sizing: border-box;
	padding: 68px 4px;
	width: 56px;
	box-shadow: inset -1px 0 0 0 #E7E9EB;
	z-index: 1200;
}

.dm-edit-toolbar__item.MuiIconButton-root {
	width: 32px;
	height: 32px;
	padding: 6px;
	margin: 8px;
	color: #2A394A;

	&.dm-edit-toolbar__item--active,
	&:hover {
		opacity: 1;
	}

	svg {
		width: 18px;
		height: 18px;
	}

	&.dm-edit-toolbar__item--active {
		background-color: #1868FD;
		color: #FFF;
	}
}

.dm-edit-sidebar {
	position: relative;
	width: 280px;
	margin-left: 56px;
	padding: 72px 16px 16px 16px;
	color: #202B38;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.dm-edit-sidebar--replace {
	width: 100%;
	margin-left: 0;
	padding: 0;
}

.dm-edit-sidebar--overlay:after {
	content: '';
	position: absolute;
	display: block;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: rgba(0,0,0,0.5);
}

.dm-edit-sidebar__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.dm-edit-sidebar__heading {
	font-size: 14px;
	line-height: 20px;
}

.dm-edit-sidebar__top {
	display: flex;

	.MuiFormControl-root {
		margin-right: 4px;
		flex: 1;
	}

	.MuiFilledInput-root {
		margin-top: 0;
		border-radius: 4px;
		height: 32px;
		background-color: #F5F7F7;

		&:hover {
			background-color: #F5F7F7;
		}

		input {
			padding-top: 5px;
		}
	}
}

.dm-edit-sidebar__middle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 12px;
	line-height: 16px;
	color: #202B38;
	margin: 10px 0;

	.MuiOutlinedInput-notchedOutline {
		display: none;
	}

	.MuiOutlinedInput-root {
		.MuiSelect-select.MuiSelect-outlined {
			padding-right: 20px;
			font-size: 12px;
			line-height: 16px;
			color: #202B38;
		}

		input {
			border-width: 0;
			padding: 0;
		}

		svg {
			right: -5px;
			width: 22px;
			height: 22px;
		}
	}
}

.dm-edit-toolbar__section {
	padding: 60px 10px 10px 10px;
	box-sizing: border-box;
	margin-left: 56px;
	width: 312px;
	min-height: 100%;
	height: auto;
}