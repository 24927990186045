.dm-edit-confirm {
	color: #202B38;
	padding: 40px 90px;
	font-size: 20px;
	font-weight: bold;
	line-height: 24px;
	max-width: 300px;
	text-align: center;
	box-shadow: 0 4px 58px 0 rgba(37,50,65,0.3);
}

.dm-edit-confirm__buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 24px;
}