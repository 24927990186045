.dm-edit-start {
	flex: 1;
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #F5F7F7;
	padding: 20px 96px;
	position: relative;
	margin-top: 56px;
}

.dm-edit-start--templates {
	justify-content: flex-start;
}

.dm-edit-start__heading {
	font-size: 24px;
	font-weight: 600;
	margin: 10px 0 15px 0;
}

.dm-edit-start__sizes {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 220px;
	margin: 20px 0;
}

.dm-edit-start__sizes--letter {
	height: 280px;
}

.dm-edit-start__size {
	border-radius: 16px;
	background-color: #fff;
	height: 137px;
	width: 216px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 24px;
	font-size: 14px;
	color: #7E8996;
	margin: 0 16px;
	transition: height ease-in-out 0.05s;

	&:hover {
		height: 220px;
		box-shadow: 0 2px 30px 0 rgba(50, 64, 71, 0.11);

		button {
			display: block;
		}
	}
}

.dm-edit-start__size--letter {
	height: 185px;

	&:hover {
		height: 270px;
	}
}

.dm-edit-start__box {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 190px;
	margin-bottom: 24px;
}

.dm-edit-start__box-crnr {
	position: absolute;
	top: -16.5px;
	right: -16.5px;
	width: 0;
	height: 0;
	border: 15px solid transparent;
	border-left-color: #FFF;
	transform: rotate(-45deg);
}

.dm-edit-start__box-out {
	box-sizing: border-box;
	border: 1px solid #B7BFC9;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before, 
	&:after {
		display: block;
		content: '';
		position: absolute;
		top: 6px;
		right: 6px;
		width: 0;
		height: 0;
		border: 12.5px solid transparent;
		border-right-color: #FFF;
		transform: rotate(-45deg);
	}

	&:before {
		top: 4.5px;
		right: 4.5px;
		border-width: 15px;
		border-right-color: #B7BFC9;
	}
}

.dm-edit-start__box-size {
	font-size: 18px;
	color: #202B38;
}

.dm-edit-start__templates {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	width: 100%;
}

.dm-edit-start__template {
	border-radius: 8px;
	width: 240px;
	height: 250px;
	margin: 16px;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	background-color: #FFF;

	img {
		width: 240px; 
		height: auto;
	}

	&:hover {
		box-shadow: 0 4px 18px 0 rgba(37,50,65,0.24);
		transition: all ease-in-out 0.15s;
	}
}

.dm-edit-start__template-label {
	background: #FFF;
	font-size: 15px;
	line-height: 52px;
	font-weight: 700;
	width: 100%;
	height: 52px;
	text-align: center;
	position: absolute;
	bottom: 0;
	left: 0;
}

.dm-edit-start__back {
	align-self: flex-start;
	position: absolute;
	top: 25px;
	left: 25px;
	color: #1868FD;
	font-size: 14px;
	display: flex;
	align-items: center;
	cursor: pointer;

	svg {
		width: 16px;
		height: 16px;
		margin-right: 8px;
	}
}
