.dm-edit-elements {
	display: flex;
	flex-wrap: wrap;
	gap: 16px 8px;
}

.dm-edit-elements__item.MuiButtonBase-root.MuiButton-root {
	font-weight: normal;
	width: 88px;
	padding: 0;
	flex-shrink: 0;
	flex-direction: column;
	text-transform: none;
	font-size: 10px;
	line-height: 12px;
	color: #415060;
	
	div {
		width: 100%;
		height: 48px;
		border-radius: 8px;
		background-color: #F5F7F7;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 4px;

		svg {
			width: 24px;
			height: 24px;
		}
	}

	&:hover {
		background-color: transparent;
	
		.dm-edit-elements__item-hover {
			display: initial;
		}

		.dm-edit-elements__item-main {
			display: none;
		}

		div {
			background-color: #1868FD;
			
			svg {
				color: #FFF;
			}
		}
	}
}

.dm-edit-elements__item-hover {
	display: none;
}

.pac-container {
	z-index: 2000;
}
