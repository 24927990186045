.dm-edit-layers {
	padding-bottom: 16px;
	
	.MuiAccordion-root {
		border-radius: 0;
		box-shadow: none;
		background-color: transparent;

		&.Mui-expanded {
			margin: 0;
		}

		&:before {
			display: none;
		}
	}

  .MuiAccordionSummary-root {
    padding: 0 0 0 17px;
    font-size: 12px;
		line-height: 18px;
    min-height: 32px;

    &.Mui-expanded {
      margin: 0;
      min-height: 32px;

			.MuiAccordionSummary-expandIconWrapper {
				transform: rotate(0deg);
			}
    }

		.MuiAccordionSummary-expandIconWrapper {
			width: 15px;
			height: 32px;
			position: absolute;
			left: 0px;
			transform: rotate(-90deg);

			.dm-edit-layer__toggle {
				padding: 0 5px;
				height: 32px;
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					margin: 0;
					width: 7px;
					height: 6px;
				}
			}
		}

		&.dm-edit-layers__item {
			.MuiAccordionSummary-content {
				height: 18px;
				display: flex;
				align-items: center;
				justify-content: space-between;
	
				section {
					display: flex;
					align-items: center;
				}
			}

			&:not(.dm-edit-layers__item--card) {
				&:hover,
				&.dm-edit-layers__item--active {
					background-color: #1868FD;
					color: #fff;
		
					svg {
						color: #fff;
					}

					.dm-edit-layer__show.MuiIconButton-root {
						display: block;
					}
				}
			}
		}
  }

	.MuiAccordionSummary-content, 
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 7px 0;
  }

	svg {
		width: 18px;
		height: 18px;
		color: #1868FD;
		margin: 0 6px;
	}

	.MuiAccordionDetails-root {
		margin-left: 8px;
		padding: 0 0 0 8px;
		border-left: 1px solid #DADFE3;

		// &:before {
		// 	content: '';
		// 	position: absolute;
		// 	width: 1px;
		// 	height: 100%;
		// 	background-color: #DADFE3;
		// }
	}

	.MuiCollapse-hidden {
		.MuiAccordionDetails-root {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			max-height: 15px;
			height: 15px;
			overflow: hidden;

			div {
				max-height: 15px;
			}
		}
	}
}

.dm-edit-layers__root {
	&.MuiAccordion-root:hover {
		background-color: transparent;
	}
	> .MuiAccordionDetails-root {
		padding: 0;
		margin: 0;
		border-left: none;
	}
}

.dm-edit-layer__show.MuiIconButton-root {
	padding: 2px;
	display: none;
	margin: 0 5px;

	svg {
		margin: 0;
		width: 14px;
		height: 14px;
	}

	&.dm-edit-layer__show--active {
		display: block;
	}
}
