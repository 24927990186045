.dm-edit-renderer {
  display: flex;
  min-height: 100vh;
}

.dm-edit-fieldgroup {
  display: flex;
  flex-direction: row;
  margin: 0 -8px 16px;

  .MuiFormControl-root {
    margin: 0 8px;
  }
}

.dm-edit-field, 
.dm-edit-field.MuiFormControl-root,
.dm-edit-field.MuiTextField-root {
  margin-bottom: 16px;
  width: 100%;
}

.sketch-picker  {
  width: 183px;
}

.dm-edit-btn.MuiButton-root {
  background-color: #F5F7F7;
  color: #7E8996;
  
  &:hover {
    color: #202B38;
  }
}
