.dm-edit-approve {
	padding: 32px 70px;
	text-align: center;

	h1 {
		margin-top: 0;
		font-size: 20px;
		line-height: 24px;
	}
}

.dm-edit-approve__checks {
	text-align: left;
	margin: 32px;
}

.dm-edit-approve__signature {
	font-weight: bold;
	text-align: left;
	font-size: 13px;
	line-height: 20px;
	margin-bottom: 5px;
}

.dm-edit-approve__note {
	margin: 0;
	text-align: left;
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 5px;
}
