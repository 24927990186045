.dm-edit-card {
  margin: 25px 0;
  position: relative;

  &:first-of-type {
    .dm-edit-card__indicia,
    .dm-edit-card__address {
      display: none;
    }
  }
}

.dm-edit-card--print {
  margin: 0px;
  padding: 20px;

  .dm-edit-cutline {
    position: absolute;
    width: 32px;
    height: 32px;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      background-color: #000;
    }

    &::before {
      width: 20px;
      height: 0.5px;
    }

    &::after {
      height: 20px;
      width: 0.5px;
    }
  }

  .dm-edit-cutline--ne {
    right: 0;
    top: 0;

    &::after {
      left: 0;
      top: 0;
    }

    &::before {
      right: 0;
      bottom: 0;
    }
  }

  .dm-edit-cutline--nw {
    left: 0;
    top: 0;

    &::after {
      right: 0;
      top: 0;
    }

    &::before {
      left: 0;
      bottom: 0;
    }
  }

  .dm-edit-cutline--se {
    right: 0;
    bottom: 0;

    &::after {
      left: 0;
      bottom: 0;
    }

    &::before {
      right: 0;
      top: 0;
    }
  }

  .dm-edit-cutline--sw {
    left: 0;
    bottom: 0;

    &::after {
      right: 0;
      bottom: 0;
    }

    &::before {
      left: 0;
      top: 0;
    }
  }

  .dm-edit-card__address {
    display: none;
    border-color: transparent;

    > div {
      display: block;
    }
    
    &:after {
      content: '';
      width: 1;
      height: 1;
      opacity: 0.01;
    }
  }

  &:not(.dm-edit-card--11x8).dm-edit-card--back {
    .dm-edit-card__address {
      display: block
    }
  }

  &.dm-edit-card--11x8.dm-edit-card--front {
    &:first-of-type
    .dm-edit-card__address {
      display: block
    }
  }

  .dm-edit-card__bleed {
    display: none;
  }
}

.dm-edit-card--4x6 {
  // 6.25x4.25
  height: 408px;
  width: 600px;
}

.dm-edit-card--6x9 {
  // 6.25x9.25
  height: 600px;
  width: 888px;
}

.dm-edit-card--6x11 {
  // 6.25x11.25
  height: 600px;
  width: 1080px;
}

.dm-edit-card--11x8 {
  // 8.75x11.25
  height: 1080px;
  width: 840px;

  .dm-edit-card__address {
    display: none;
  }

  .dm-edit-card__address {
    width: 413px;
    height: 221px;
    top: 83px;
    left: 66px;
    bottom: unset;
    right: unset;
  }

  &:not(.dm-edit-card--print):first-of-type .dm-edit-card__address {
    display: flex;
  }

  .dm-edit-post-line--live {
    top: 24px;
    left: 24px;
    width: calc(100% - 48px); 
    height: calc(100% - 48px);
  }
}

.dm-edit-card__address {
  width: 360px;
  height: 216px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 12px;
  bottom: 12px;
  z-index: 2;
  background-color: #FFF;
  box-sizing: border-box;
  border: 5px dashed rgba(231, 236, 240, 0.651);

  p {
    position: absolute;
    left: 17px;
    top: 30px;
    font-family: USPSIMBCompact; 
    font-size: 14pt;
  }

  > div {
    position: absolute;
    left: 17px;
    top: 72px;
    display: none;
  }

  &:after {
    content: 'Address';
    display: block;
    text-align: center;
    transform: rotate(-20deg);
    color: rgba(231, 236, 240, 0.651);
    font-weight: bold;
    font-size: 70px;
  }
}

.dm-edit-card__bleed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 12px solid rgba(231, 236, 240, 0.651);
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
}

.dm-edit-card__indicia {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 120px;
  height: 120px;
  background-color: #FFF;
  z-index: 999;
  border: 1px solid #000;
  display: flex;
  align-items: center;

  div {
    width: 100%;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    font-weight: bold;
  }
}

.dm-edit-post-line {
  z-index: 100;
  position: absolute;
  width: 20px;
  height: 20px;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;

  &:before {
    left: -23px;
    display: block;
    position: absolute;
    font-size: 11px;
    transform: rotate(-90deg) translate(-50%) translate(7px);
  }
}

.dm-edit-post-line--bleed {
  top: -12px;
  left: -12px;
  width: calc(100% + 24px); 
  height: calc(100% + 24px);
  border: 1px solid #159BE3;

  &:before {
    content: 'Bleed';
    color: #159BE3;
  }
}

.dm-edit-post-line--buffer {
  top: 0px;
  left: 0px;
  width: 100%; 
  height: 100%;
  border: 1px solid #E7218D;

  &:before {
    content: 'Buffer';
    color: #E7218D;
  }
}

.dm-edit-post-line--live {
  top: 12px;
  left: 12px;
  width: calc(100% - 24px); 
  height: calc(100% - 24px);
  border: 1px solid #6220CC;

  &:before {
    left: -30px;
    content: 'Live Area';
    color: #6220CC;
  }
}

.dm-edit-card__canvas {
  position: relative;
  overflow: hidden;
  height: 100%;
}