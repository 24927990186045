.dm-edit-section {
	width: 100%;
	min-height: 90px;
	padding: 0 200px;
	box-sizing: border-box;
  position: relative;
	height: fit-content;
}

.dm-edit-section--grid {
	display: grid;
	grid-template-columns: repeat(36, 1fr);
  grid-auto-rows: 15px;
}

.dm-edit-section--columns {
	display: flex;
}

.dm-edit-section--mobile {
	min-height: unset;
}

.dm-edit-section__grid {
  position: absolute;
	background-image: url('./grid.svg');
	background-size: 25px 15px;
  width: 900px;
  height: 100%;
  left: 200px;
  z-index: 0;
	pointer-events: none;
}
