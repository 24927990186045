.dm-edit-form__group {
	display: flex;
}

.dm-edit-form-props__group {
	width: 100%;
	box-sizing: border-box;
	background-color: #F5F7F7;
	border: 1px solid #EAECEE;
	border-radius: 4px;
	margin-bottom: 8px;
	padding: 8px;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.dm-edit-form-props__field {
	gap: 8px;
	display: flex;
	align-items: center;
}

.dm-edit-form-props__field-main {
	background-color: #FFFFFF;
	border: 1px solid #EAECEE;
	border-radius: 4px;
	padding: 7px;
	color: #202B38;
	font-size: 12px;
	display: flex;
	align-items: center;
	flex: 1;
	transition: all 0.1s ease-in-out;
	cursor: pointer;

	&:hover {
		border-color: #73787D;

		.dm-edit-form-props__field-gear.MuiIconButton-root {
			opacity: 1;
		}
	}
}

.dm-edit-form-props__field-drag {
	height: 15px;
	width: 15px;
	margin-right: 6px;

	svg  {
		width: 15px;
		height: 15px;
		color: #808080;
	}
}

.dm-edit-form-props__field-gear.MuiIconButton-root {
	opacity: 0;
	padding: 3px;
	transition: all 0.1s ease-in-out;

	svg {
		width: 15px;
		height: 15px;
		color: #73787D;
	}
}

.dm-edit-form-props__field-remove.MuiIconButton-root {
	padding: 3px;

	svg  {
		width: 9px;
		height: 9px;
	}
}

.dm-edit-form-props__add.MuiButton-root {
	padding: 0;
	margin: 17px auto;
	font-weight: 500 !important;
	text-transform: none;
	display: block;
}

.dm-edit-form-props__menu {
	width: 208px;
	z-index: 1299;
	margin-right: 16px !important;
	padding: 16px;
	box-shadow: 0px 4px 58px rgba(37, 50, 65, 0.2);
	border-radius: 8px;
	background: #FFFFFF;
	display: flex;
	flex-direction: column;
	gap: 6px;
}
