.dm-edit-image__clone {
	height: 100%;
}

.dm-edit-image__clone,
.dm-edit-image__main {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 50%;
}

.dm-edit-image__draggable {
	pointer-events: all;
}

.dm-edit-image__draggable--locked {
	pointer-events: none;
}

.dm-edit-image__ind {
	position: absolute;
	width: 10px;
	height: 10px;
	border: 2px solid #fff;
	pointer-events: all;
}

.dm-edit-image__ind--tl {
	top: 0;
	left: 0;
	border-right: none;
	border-bottom: none;
}

.dm-edit-image__ind--bl {
	bottom: 0;
	left: 0;
	border-right: none;
	border-top: none;
}

.dm-edit-image__ind--tr {
	top: 0;
	right: 0;
	border-left: none;
	border-bottom: none;
}

.dm-edit-image__ind--br {
	bottom: 0;
	right: 0;
	border-left: none;
	border-top: none;
}
