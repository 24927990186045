.dm-edit-render-indicator {
  font-size: 12px;
  line-height: 12px;
  position: fixed;

  &:after {
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    border: 2px solid #1868FD;
    top: 0;
    right: 0;
    box-sizing: border-box;
  }
}

.dm-edit-render-indicator--section .dm-edit-handle--bottom{
  transform: translateX(200px);
}

.dm-edit-move {
  cursor: move;
  pointer-events: all; 
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 14px;
    width: 14px;
  }
}

.dm-edit-handle {
  border-radius: 20px;
  background-color: #fff;
  border: 2px solid #1868FD;
  pointer-events: all;
}

.dm-edit-handle--bottom,
.dm-edit-handle--top {
  max-width: 22px;
  max-height: 6px;
  left: 50% !important;
  transform: translateX(-50%);
}

.dm-edit-handle--left,
.dm-edit-handle--right {
  max-height: 22px;
  max-width: 6px;
  top: 50% !important;
}

.dm-edit-handle--left {
  left: 0 !important;
  transform: translate(-4px, -50%);
}

.dm-edit-handle--right {
  right: 0 !important;
  transform: translate(4px, -50%);
}

.dm-edit-handle--topLeft,
.dm-edit-handle--bottomLeft,
.dm-edit-handle--bottomRight,
.dm-edit-handle--topRight {
  max-width: 6px;
  max-height: 6px;
}

.dm-edit-handle--topLeft {
  left: 0 !important;
  top: 0 !important;
  transform: translate(-4px, -4px);
}

.dm-edit-handle--topRight {
  right: 0 !important;
  top: 0 !important;
  transform: translate(4px, -4px);
}

.dm-edit-handle--bottomLeft {
  left:0 !important;
  bottom: 0 !important;
  transform: translate(-4px, 4px);
}

.dm-edit-handle--bottomRight {
  right: 0 !important;
  bottom: 0 !important;
  transform: translate(4px, 4px);
}

.dm-edit-rendernode__name {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);

  .dm-edit-item-cntrl {
    font-size: 10px;
    line-height: 12px;
    padding: 0;
    margin: 0;
    border-radius: 0;

    > span {
      padding: 6px;

      svg {
        display: none;
      }
    }

    .MuiIconButton-root {
      width: 16px;
      height: 16px;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.dm-edit-add-section.MuiButton-root {
  background-color: #1868FD;
  color: #FFF;
  text-transform: none;
  font-size: 10px;
  line-height: 12px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: none;
}

.dm-edit-add-section--before.MuiButton-root {
 top: 0;
}
