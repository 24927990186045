.dm-edit-img-btn.MuiButton-root {
	justify-content: flex-start;
	padding: 4px;
	margin-bottom: 4px;
	font-size: 12px;
	color: #202B38;
	text-transform: none;
	line-height: 16px;
	letter-spacing: -0.17px;
	max-width: 100%;
}

.dm-edit-img-btn__name {
	flex: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
}

.dm-edit-img-btn__img {
	width: 32px;
	height: 32px;
	border-radius: 2.77px;
	background-color: #EBF0F3;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 9px;

	img {
		max-width: 100%;
		max-height: 100%;
	}
}

.dm-edit-images--tiles {
	.dm-edit-img-btn__img {
		width: 88px;
		height: 88px;
		margin-right: 0;
	}

	.dm-edit-img-btn__name {
		width: 88px;
		text-align: center;
	}

	.dm-edit-img-btn.MuiButton-root {
		flex-direction: column;
		width: 88px;
		margin: 0 4px 12px 4px;
	}
}

.dm-edit-img-btn--tiles {
	width: 88px;
	height: 88px;
	border-radius: 4px;
}

// .dm-edit-images__item {
// 	max-width: 85px;
// 	height: auto;
// 	max-height: 50px;
// 	// margin: 10px 5px;
// }
